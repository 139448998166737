<template>
    <v-stepper-content :step="step">
        <v-responsive class="grey lighten-4 pa-8">
            <v-row no-gutters justify="center">
                <v-avatar size="50" color="white">
                    <v-icon size="50" color="primary">mdi-check-circle</v-icon>
                </v-avatar>
            </v-row>
            <div class="text-center mt-4">
                <div class="title"><b>회원가입이 완료되었습니다.</b></div>
                <div class="body-1">가입해 주셔서 감사합니다.</div>
                <v-btn outlined x-large color="primary" to="/login" class="mt-6">로그인 페이지로 이동</v-btn>
            </div>
        </v-responsive>
    </v-stepper-content>
</template>

<script>
export default {
    props: ["step", "value"]
}
</script>

<style scoped>
.title {
    font-size: 36px;
    margin-bottom: 5px;
}
</style>