var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-stepper-content', {
    attrs: {
      "step": _vm.step
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, _vm._l(_vm.userTypes, function (_ref, index) {
    var type = _ref.type,
      info = _ref.info;
    return _c('v-card', {
      key: index,
      staticClass: "ma-1"
    }, [_c('v-card-title', {
      staticClass: "d-block text-center blue lighten-1"
    }, [_c('v-icon', {
      attrs: {
        "color": "white",
        "size": "24"
      }
    }, [_vm._v(_vm._s(info.icon))])], 1), _c('v-card-text', {
      staticClass: "text-center pa-4"
    }, [_c('span', [_c('b', [_vm._v(_vm._s(info.name))])]), _c('div', [_c('p', {
      domProps: {
        "innerHTML": _vm._s(info.description.replace(/\n/g, '<br>'))
      }
    })]), _c('v-btn', {
      staticClass: "white--text",
      attrs: {
        "outlined": "",
        "color": "blue"
      },
      on: {
        "click": function ($event) {
          return _vm.next(type);
        }
      }
    }, [_vm._v("가입하기")])], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }