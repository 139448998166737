var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.template, {
    tag: "component"
  }, [_c('v-layout', {
    staticClass: "py-15",
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "max-width": "1024"
    }
  }, [_c('v-stepper', {
    staticClass: "elevation-0 mx-n3",
    attrs: {
      "vertical": ""
    },
    model: {
      value: _vm.step,
      callback: function ($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-stepper-header', {
    staticClass: "ma-3 elevation-0 justify-center align-center"
  }, [_c('span', [_c('b', [_vm._v("회원가입")])])]), _c('v-divider'), _c('v-stepper-items', [_vm.visibility('가입유형') ? [_c('v-stepper-step', {
    attrs: {
      "step": _vm.toStep('가입유형'),
      "color": "primary"
    }
  }, [_vm._v("가입유형")]), _c('step-type', {
    attrs: {
      "step": _vm.toStep('가입유형')
    },
    on: {
      "next": function ($event) {
        _vm.step++;
      }
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })] : _vm._e(), [_c('v-stepper-step', {
    attrs: {
      "step": _vm.toStep('약관동의'),
      "color": "primary"
    }
  }, [_vm._v("약관동의")]), _c('step-terms', {
    attrs: {
      "step": _vm.toStep('약관동의'),
      "filled": "",
      "background-color": "#E6F5FF67"
    },
    on: {
      "next": function ($event) {
        _vm.step++;
      }
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })], [_c('v-stepper-step', {
    attrs: {
      "step": _vm.toStep('정보입력'),
      "color": "primary"
    }
  }, [_vm._v("정보입력")]), _vm.user.type ? _c('step-form', {
    attrs: {
      "step": _vm.toStep('정보입력'),
      "height": "44"
    },
    on: {
      "next": function ($event) {
        _vm.step++;
      }
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  }) : _vm._e()], [_c('v-stepper-step', {
    attrs: {
      "step": _vm.toStep('가입완료'),
      "color": "primary"
    }
  }, [_vm._v("가입완료")]), _c('step-complete', {
    attrs: {
      "step": _vm.toStep('가입완료')
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })]], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }