var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-stepper-content', {
    attrs: {
      "step": _vm.step
    }
  }, [_c('v-responsive', {
    staticClass: "grey lighten-4 pa-8"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "center"
    }
  }, [_c('v-avatar', {
    attrs: {
      "size": "50",
      "color": "white"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "50",
      "color": "primary"
    }
  }, [_vm._v("mdi-check-circle")])], 1)], 1), _c('div', {
    staticClass: "text-center mt-4"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('b', [_vm._v("회원가입이 완료되었습니다.")])]), _c('div', {
    staticClass: "body-1"
  }, [_vm._v("가입해 주셔서 감사합니다.")]), _c('v-btn', {
    staticClass: "mt-6",
    attrs: {
      "outlined": "",
      "x-large": "",
      "color": "primary",
      "to": "/login"
    }
  }, [_vm._v("로그인 페이지로 이동")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }