<template>
    <v-stepper-content :step="step">
        <v-card outlined>
            <v-card-title class="justify-center">
                <v-checkbox ref="all" :input-value="user._terms.length == termsList.length" label="전체 이용약관에 동의합니다." hide-details @click.stop="all" class="ma-0 pa-0 font-weight-bold"/>
            </v-card-title>
        </v-card>

        <div v-for="terms in termsList" :key="terms._id" class="mt-6">
            <v-layout no-gutters align-center>
                <span><b>{{ `${terms.subject} ${terms.required ? "(필수)" : "(선택)"}` }}</b></span>
                <v-spacer/>
                <v-checkbox v-model="user._terms" :value="terms._id" label="동의" hide-details multiple class="mt-0"/>
            </v-layout>
            <v-sheet width="100%" height="2" color="primary" class="mt-3"/>
            <v-responsive width="100%" height="40vh" max-height="40vh" class="overflow-auto">
                <p v-html="terms.content" />
            </v-responsive>
        </div>

        <v-btn width="100%" outlined x-large color="primary" @click="next" class="mt-6">동의함</v-btn>
    </v-stepper-content>
</template>

<script>
import api from "@/api";
export default {
    props: ["value", "step"],
    data(){
        return {
            user: this.$props.value,

            termsList: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { termsList } = await api.v1.terms.gets({ headers: { limit: 0 }, params: { code: ["tos", "privacy"] } });
            this.termsList = termsList;
        },
        all(){
            let all = this.termsList.length == this.user._terms.length;
            this.user._terms = all ? [] : this.termsList.map(terms => terms._id);
        },
        input(){

        },
        validate(){
            try{
                for(let terms of this.termsList){
                    if(terms.required && !this.user._terms.includes(terms._id)) throw new Error(`[${terms.name}] 에 동의해주세요`);
                }
                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        next(){
            if(this.validate()){
                this.$emit("next");
            }
        }
    }
}
</script>