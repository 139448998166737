import { render, staticRenderFns } from "./step-complete.vue?vue&type=template&id=bf0b5db2&scoped=true"
import script from "./step-complete.vue?vue&type=script&lang=js"
export * from "./step-complete.vue?vue&type=script&lang=js"
import style0 from "./step-complete.vue?vue&type=style&index=0&id=bf0b5db2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf0b5db2",
  null
  
)

export default component.exports