<template>
    <v-stepper-content :step="step">
        <v-layout justify-center>
            <v-card v-for="({ type, info }, index) in userTypes" :key="index" class="ma-1">
                <v-card-title class="d-block text-center blue lighten-1"><v-icon color="white" size="24">{{ info.icon }}</v-icon></v-card-title>
                <v-card-text class="text-center pa-4">
                    <span><b>{{ info.name }}</b></span>
                    <div><p v-html="info.description.replace(/\n/g, '<br>')"></p></div>
                    <v-btn outlined color="blue" class="white--text" @click="next(type)">가입하기</v-btn>
                </v-card-text>
            </v-card>
        </v-layout>
    </v-stepper-content>
</template>
<script>
export default {
    props: ["step", "value"],
    data(){
        return {
            user: this.$props.value,
            userTypes: [
                {
                    type: "member",
                    info: {
                        name: "일반회원",
                        icon: "mdi-account",
                        description: "다양하고 푸짐한 이벤트가 준비되어 있습니다."
                    }
                },
            ],
        }
    },
    methods: {
        next(type){
            this.user.type = type;
            this.$emit("input", this.user);
            this.$emit("next");
        }
    },
    watch: {
        value(){
            this.user = this.value;
        }
    }
}
</script>