<template>
    <component :is="template">
        <v-layout justify-center align-center class="py-15">
            <v-responsive width="100%" max-width="1024">
                <v-stepper v-model="step" vertical class="elevation-0 mx-n3">
                    <v-stepper-header class="ma-3 elevation-0 justify-center align-center">
                        <span><b>회원가입</b></span>
                    </v-stepper-header>
                    <v-divider/>

                    <v-stepper-items>
                        <template v-if="visibility('가입유형')">
                            <v-stepper-step :step="toStep('가입유형')" color="primary">가입유형</v-stepper-step>
                            <step-type v-model="user" :step="toStep('가입유형')" @next="step++" />
                        </template>
                        
                        <template>
                            <v-stepper-step :step="toStep('약관동의')" color="primary">약관동의</v-stepper-step>
                            <step-terms v-model="user" :step="toStep('약관동의')" @next="step++" filled background-color="#E6F5FF67"/>
                        </template>

                        <template>
                            <v-stepper-step :step="toStep('정보입력')" color="primary">정보입력</v-stepper-step>
                            <step-form v-if="user.type" v-model="user" :step="toStep('정보입력')" @next="step++" height="44" />
                        </template>

                        <template>
                            <v-stepper-step :step="toStep('가입완료')" color="primary">가입완료</v-stepper-step>
                            <step-complete v-model="user" :step="toStep('가입완료')"/>
                        </template>
                    </v-stepper-items>
                </v-stepper>
            </v-responsive>
        </v-layout>
    </component>
</template>
<script>
import StepType from "@/components/client/join/step-type.vue";
import StepTerms from "@/components/client/join/step-terms.vue";
import StepForm from "@/components/client/join/step-form.vue";
import StepComplete from "@/components/client/join/step-complete.vue";

export default {
    components: {
        StepType,
        StepTerms,
        StepForm,
        StepComplete
    },
    data(){
        return {
            step: 1,
            steps: [
                // "가입유형", // <-- 안쓸때 주석
                "약관동의", "정보입력", "가입완료"
            ],

            user: {
                _terms: [],

                username: null,
                password: null,

                type: "member",
                name: null,
                
                phone: null,
                phone1: null,
                phone2: null,
                phone3: null,

                email: null,
                email2: null,
                email3: null,

                birthday: null,

                postcode: null,
                address1: null,
                address2: null,

                smsEnabled: true
            },
        }
    },
    mounted(){
        
    },
    methods: {
        visibility(step){
            return this.steps.includes(step);
        },
        toStep(step){
            return this.steps.indexOf(step) + 1;
        }
    },

    computed: {
        template(){
            return () => import(`@/templates/${this.$theme.pages.default}`);
        }
    }
}
</script>

<style scoped>
.v-stepper__header {
    font-size: 36px;
}
</style>