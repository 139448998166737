<template>
    <v-stepper-content :step="step">
        <component v-model="user" :is="skin" @input="input" @next="next" v-bind="$attrs"/>
    </v-stepper-content>
</template>

<script>
export default {
    props: ["value", "step"],

    data(){
        return {
            user: this.$props.value,
        }
    },
    methods: {
        input(){
            this.$emit("input", this.user);
        },
        next(){
            this.$emit("next");
        }
    },
    computed: {
        skin() {
            return () => import(`./skin/${this.user.type}/form.vue`);
        }
    },
    watch: {
        value(){
            this.user = this.value;
        }
    }
}
</script>