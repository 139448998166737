var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-stepper-content', {
    attrs: {
      "step": _vm.step
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "justify-center"
  }, [_c('v-checkbox', {
    ref: "all",
    staticClass: "ma-0 pa-0 font-weight-bold",
    attrs: {
      "input-value": _vm.user._terms.length == _vm.termsList.length,
      "label": "전체 이용약관에 동의합니다.",
      "hide-details": ""
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.all.apply(null, arguments);
      }
    }
  })], 1)], 1), _vm._l(_vm.termsList, function (terms) {
    return _c('div', {
      key: terms._id,
      staticClass: "mt-6"
    }, [_c('v-layout', {
      attrs: {
        "no-gutters": "",
        "align-center": ""
      }
    }, [_c('span', [_c('b', [_vm._v(_vm._s(`${terms.subject} ${terms.required ? "(필수)" : "(선택)"}`))])]), _c('v-spacer'), _c('v-checkbox', {
      staticClass: "mt-0",
      attrs: {
        "value": terms._id,
        "label": "동의",
        "hide-details": "",
        "multiple": ""
      },
      model: {
        value: _vm.user._terms,
        callback: function ($$v) {
          _vm.$set(_vm.user, "_terms", $$v);
        },
        expression: "user._terms"
      }
    })], 1), _c('v-sheet', {
      staticClass: "mt-3",
      attrs: {
        "width": "100%",
        "height": "2",
        "color": "primary"
      }
    }), _c('v-responsive', {
      staticClass: "overflow-auto",
      attrs: {
        "width": "100%",
        "height": "40vh",
        "max-height": "40vh"
      }
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(terms.content)
      }
    })])], 1);
  }), _c('v-btn', {
    staticClass: "mt-6",
    attrs: {
      "width": "100%",
      "outlined": "",
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v("동의함")])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }