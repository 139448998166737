var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-stepper-content', {
    attrs: {
      "step": _vm.step
    }
  }, [_c(_vm.skin, _vm._b({
    tag: "component",
    on: {
      "input": _vm.input,
      "next": _vm.next
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  }, 'component', _vm.$attrs, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }